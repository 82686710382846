<template>
  <v-card class="rounded-0 app-marketplace h-full position-relative overflow-hidden text-sm">
    <!-- Viewer -->
    <!-- :title="$t('ProductSupplierList.table.dialog_delete.title')" -->
    <!-- :text="$t('ProductSupplierList.table.dialog_delete.text')" -->
    <!-- :button="$t('ProductSupplierList.table.dialog_delete.button_text')" -->
    <MarketplaceProductSupplierDialog
      :id="productSupplierViewId"
      v-model="isProductSupplierViewDialogActive"
      :title="$t('MarketplaceProductSupplierDialog.title')"
      :text="$t('MarketplaceProductSupplierDialog.text')"
      :button="$t('MarketplaceProductSupplierDialog.button_text')"
      @connect="(id) => onProductSupplierConnect(id)"
    ></MarketplaceProductSupplierDialog>

    <!-- Dialog Supplier: Ver detalle del proveedor -->
    <!-- <CompanyDialog
      v-if="supplierDialog"
      v-model="isSupplierDialogActive"
      :company="supplierDialog.company"
    ></CompanyDialog> -->

    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <MarketplaceCatalogLeftSidebar
        :product-demander-filter-ids.sync="productDemanderIdsFilter"
        :product-demander-filter-options="productDemanderFilterOptions"
      />
      <!-- Footer -->
      <!-- @click="onLogout()" -->
      <!-- <template v-slot:append>
        <v-list-item
          class="navbar fixed-bottom"
        >
          <v-list-item-icon>
            <v-icon color="error">
              {{ icons.mdiMagnify }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template> -->
    </v-navigation-drawer>

    <!-- Content Area -->
    <div
      class="h-full position-relative"
      :style="contentStyles"
    >
      <!-- Top Actions -->
      <!-- flex-wrap: Se puede romper líneas; flex-nowrap: Misma linea -->
      <div class="d-md-flex align-center justify-space-between flex-wrap mt-4 px-2">
        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <!-- Menu View Filter Left Side -->
          <FilterButton
            :count-filtering="countFiltering"
            :is-left-sidebar-open.sync="isLeftSidebarOpen"
            class="mr-2"
          ></FilterButton>

          <!-- SortBy and OrderBy TODO: Pasar sortBy como array (Es el elemento soportado v-data-table)-->
          <TableSortExternal
            :sort-by.sync="options.sortBy[0]"
            :sort-desc.sync="options.sortDesc[0]"
            :table-columns="tableColumns"
          ></TableSortExternal>

          <!-- Table Vs GridCard view -->
          <TableToggleView
            :is-table-visible.sync="isTableVisible"
            class="ml-2"
          ></TableToggleView>
        </div>

        <!-- Break if small -->
        <v-divider
          v-if="$vuetify.breakpoint.smAndDown"
          class="break mt-4 mb-2"
        ></v-divider>

        <!-- Row1(Right) or Row2(Mobile) -->
        <div class="d-flex align-center justify-center flex-nowrap">
          <!-- search -->
          <v-text-field
            v-model="searchQueryFilter"
            :placeholder="$t('ProductSupplierList.table.search')"
            outlined
            small
            hide-details
            clearable
            class="marketplace-search-input"
            :prepend-inner-icon="icons.mdiMagnify"
          ></v-text-field>

          <!-- Refresh -->
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            class="ms-2"
            @click="fetchMarketplaceProductSuppliers()"
          >
            <v-icon size="21">
              {{ icons.mdiReload }}
            </v-icon>
          </v-btn>

          <!-- Country filter -->
          <!-- <v-btn
            color="primary"
            outlined
            small
            @click="onAddNewProductSupplierClick"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span class="d-none d-md-block">{{ $t('ProductSupplierList.table.buttom_add') }}</span>
          </v-btn> -->
        </div>
      </div>

      <!-- Table -->
      <!-- Options ya resuelve todos estos parametros que cambian
        :items-per-page="options.itemsPerPage"
        :page.sync="options.page"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
      -->
      <v-data-table
        v-if="isTableVisible"
        ref="refTable"
        v-model="selectedRows"
        :headers="tableColumns"
        :items="itemListTable"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        :hide-default-footer="true"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :loading="loading"
        :show-select="false"
        :single-select="true"
        mobile-breakpoint="200"
        @click:row="(item) => onProductSupplierClick(item)"
      >
        <!-- Name & Description -->
        <template #[`item.name`]="{item}">
          <div class="d-flex flex-column">
            <span>{{ item.name }}</span>
            <small
              class="text-truncate text-muted"
              style="max-width: 200px;"
            >
              {{ filterTags(item.description) }}
            </small>
          </div>
        </template>

        <!-- Product / Category-->
        <template #[`item.product`]="{item}">
          <ProductSupplierCard :product-supplier="item" />
        </template>

        <!-- Column: ProductConservation -->
        <template #[`item.productConservation`]="{item}">
          <span class="text-nowrap">
            {{ item.productConservation.name }}
          </span>
        </template>

        <!-- Column: productPresentation -->
        <template #[`item.productPresentation`]="{item}">
          <span class="text-nowrap">
            {{ item.productPresentation.name }}
          </span>
        </template>

        <!-- Column: Tags -->
        <template #[`item.tags`]="{item}">
          <div class="badge-wrapper mr-1">
            <v-chip
              v-for="tag in item.tags"
              :key="tag"
              small
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ tag }}
            </v-chip>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex flex-nowrap align-center">
            <v-icon
              size="20"
              @click.stop="onProductSupplierClick(item)"
            >
              {{ icons.mdiEye }}
            </v-icon>
            <!-- @click.stop="onProductSupplierConnect(item.id)" -->
            <v-icon
              class="ml-2"
              size="20"
              color="primary"
              @click.stop="onProductSupplierClick(item)"
            >
              {{ icons.mdiEmailSendOutline }}
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <!-- Grid System: Table Card -->
      <div v-if="!isTableVisible">
        <!-- Loading indicator -->
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
          class="position-absolute"
        />
        <v-divider></v-divider>

        <v-row
          dense
          class="row-container pt-4"
        >
          <v-col
            v-for="productSupplier in itemListTable"
            :key="productSupplier.id"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-hover
              #default="{ hover: isHovered }"
            >
              <!-- En la ficha se visualiza perfectamente la acción: Quitamos el clic -->
              <div
                :class="[{'hovered elevation-3': isHovered}]"
                class="grid-box cursor-pointer"
                @click="onProductSupplierClick(productSupplier)"
              >
                <!-- @on-supplier-click="(supplier) => onSupplierDialogClick(supplier)" -->
                <MarketplaceCatalogProductSupplierGridCard
                  :product-supplier="productSupplier"
                  @on-action-view="(productSupplier) => onProductSupplierClick(productSupplier)"
                  @on-action-connect="(productSupplier) => onProductSupplierConnect(productSupplier.id)"
                />
              </div>
            </v-hover>
          </v-col>
        </v-row>
      </div>
      <!-- // Grid System: Table Card -->

      <!-- Footer: v-data-footer: Pagination -->
      <!-- Pagination: Usamos options porque está referenciada con v-data-table -->
      <TablePagination
        :options.sync="options"
        :items-length="serverItemsLength"
        :items-per-page-options="itemsPerPageOptions"
      ></TablePagination>
      <!-- // Footer -->
    </div>
  </v-card>
</template>

<script>
import {
  mdiEmailSendOutline, mdiEye, mdiMagnify, mdiPlus, mdiReload,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// UX
// import { getVuetify } from '@core/utils'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { avatarText, filterTags, formatDateToMonthShort } from '@core/utils/filter'
import store from '@/store'
import { useRouter } from '@/@core/utils'

// Components
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import FilterButton from '@/components/FilterButton.vue'
import TablePagination from '@/components/TablePagination.vue'
import TableSortExternal from '@/components/TableSortExternal.vue'
import TableToggleView from '@/components/TableToggleView.vue'

import CompanyDialog from '@/components/Dialog/CompanyDialog.vue'
import MarketplaceProductSupplierDialog from './MarketplaceProductSupplierDialog.vue'

// Components 2
// import ProductFilters from '../../ProductFilters.vue'
import MarketplaceCatalogLeftSidebar from './MarketplaceCatalogLeftSidebar.vue'
import MarketplaceCatalogProductSupplierGridCard from './MarketplaceCatalogProductSupplierGridCard.vue'

// UI Services
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Services
import useContactRequest from '@/services/api/modules/contactRequest'
import useMarketplaceHandler from '../useMarketplaceHandler'
import useMarketplaceCatalog from './useMarketplaceCatalog'

// Services
// import useMarketplace from '@/services/api/modules/marketplace'

export default {
  components: {
    // ProductSupplierHandleSidebar,
    MarketplaceCatalogLeftSidebar,
    ProductSupplierCard,
    MarketplaceCatalogProductSupplierGridCard,
    TableSortExternal,
    TableToggleView,
    FilterButton,
    TablePagination,

    // Custom component
    MarketplaceProductSupplierDialog,
    // eslint-disable-next-line vue/no-unused-components
    CompanyDialog,
  },

  setup() {
    // const PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME = 'app-product-supplier'
    // // Register module
    // if (!store.hasModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME, productSupplierStoreModule)
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_SUPPLIER_APP_STORE_MODULE_NAME)
    // })
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 })

    // Toastification
    const { sendMarketplaceNotification } = useMarketplaceHandler()

    // TODO - Analizar si es mejor indicarlo dentro de useMarketplaceCatalog
    // Navegación: Filtro indicada en origen
    //  Buscamos el id en los query param
    const { route } = useRouter()
    const initProduductDemanderId = route.value.query.productDemanderId

    // Table (MarketplaceProductSuppliers)
    const {
      // Table
      refTable,
      tableColumns,
      options,
      itemsPerPageOptions,

      loading,
      selectedRows,
      itemListTable,
      serverItemsLength,

      // Fetch data
      fetchMarketplaceProductSuppliers,

      // Filters
      searchQueryFilter,
      countryFilter,

      // Filters: Country, ProductDemanderIds
      countFiltering,
      productDemanderIdsFilter,
      productDemanderFilterOptions,
    } = useMarketplaceCatalog(initProduductDemanderId)

    // *** FILTRAMOS en el useMarketplaceCatalog ***
    // ProductDemander
    // const productDemanderFilterOptions = ref([])
    // const fetchMarketplaceProductDemanders = () => {
    //   useMarketplace.fetchProductDemanders({
    //     isMarketplaceAvailable: true,
    //   })
    //     .then(response => {
    //       const { productDemanders } = response.data
    //       if (productDemanders) {
    //         productDemanderFilterOptions.value = response.data.productDemanders
    //       } else {
    //         productDemanderFilterOptions.value = []
    //       }
    //     })
    // }
    // fetchMarketplaceProductDemanders()

    // Table Vs Card list (Default)
    // const isTableVisible = ref(false)
    const { isTableVisibleMarketplaceCatalog: isTableVisible } = useAppConfigUser()

    // Create ProductSupplier by Sidebar
    // const isMarketplaceProductSupplierDialogActive = ref(false)
    // const productSupplierSelected = ref(null)
    // const onHandleProductSupplierClick = item => {
    //   console.log(item)

    //   // .stop prevent no funciona
    //   if (item instanceof Array) {
    //     // eslint-disable-next-line prefer-destructuring
    //     productSupplierSelected.value = item[0]
    //   } else {
    //     productSupplierSelected.value = item
    //   }

    //   isMarketplaceProductSupplierDialogActive.value = !!productSupplierSelected.value
    // }

    // ProductSupplier Dialog Viewer and connect
    // const isSupplierDialogActive = ref(false)
    // const supplierDialog = ref(null)
    // const onSupplierDialogClick = supplier => {
    //   debugger
    //   isSupplierDialogActive.value = true
    //   supplierDialog.value = supplier
    // }

    // ProductSupplier Dialog Viewer and connect
    const isProductSupplierViewDialogActive = ref(false)
    const productSupplierViewId = ref('')
    const onProductSupplierClick = productSupplier => {
      isProductSupplierViewDialogActive.value = true
      productSupplierViewId.value = productSupplier.id
    }

    const onProductSupplierConnect = productSupplierId => {
      // TODO - Create on Draft forder
      // ContactRequest(Published)
      useContactRequest
        .createContactRequest({
          status: 'published',
          productSupplierId,
          subject: 'Sin Asunto',
          message: 'Sin mensaje',
        })

        // .then(() => {
        //   sendMarketplaceNotification('Supplier Connected', 'success')
        //   fetchMarketplaceProductSuppliers()
        // })
        .then(response => {
          const { contactRequest } = response.data
          if (contactRequest) {
            // useMarketplace.removeProductSupplierFavorite(this.productSuppliers[0].id)
            //   .then(
            //     console.log('Item remove'),
            //   )

            // SharedUtils.makeToast(this, 'success', 'Request', 'Contact sent')
            sendMarketplaceNotification('Supplier Connected', 'success')

            // Store - Add ContactRequest
            store.commit(
              'user/UPDATE_CONTACT_REQUESTS_PENDING_COUNT',
              store.getters['user/contactRequestsPendingCount'] + 1,
            )

            // Go to contact Request (Not active)
            // this.$router.push({ name: 'apps-contact-request-folder', params: { folder: 'published' } })
          }
          fetchMarketplaceProductSuppliers()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            sendMarketplaceNotification(error.response.data.error.message, 'error')
          }
        })
    }

    return {
      // Table
      refTable,
      tableColumns,
      searchQueryFilter, // Filter
      options,
      itemsPerPageOptions,

      loading,
      selectedRows,
      itemListTable,
      serverItemsLength,

      // Table Vs Grid card
      isLeftSidebarOpen,
      isTableVisible,
      contentStyles,

      // UI
      filterTags,
      avatarText,
      formatDateToMonthShort,

      // Data
      fetchMarketplaceProductSuppliers,

      // Filters
      countryFilter,

      // Filters: Country, ProductDemanderIds
      countFiltering,
      productDemanderIdsFilter,
      productDemanderFilterOptions,

      // Dialog Supplier
      // isSupplierDialogActive,
      // supplierDialog,
      // onSupplierDialogClick,

      // Viewer
      isProductSupplierViewDialogActive,
      productSupplierViewId,
      onProductSupplierClick,
      onProductSupplierConnect,

      // icons
      icons: {
        mdiMagnify,
        mdiEmailSendOutline,
        mdiEye,
        mdiPlus,
        mdiReload,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
/* Inserting this collapsed row between two flex items will make
    * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

.grid-box {
  border-radius: 5px;
  border: 1px solid rgba(94, 86, 105, 0.14);
  // background: lightgray;
}
// ROW/COLS
.row-container {
  margin-left: 8px;
  margin-right: 8px;
}
.row-container > * {
  // max-width: 300px;
}
</style>

<style lang="scss">
.app-marketplace {
  // Style search input
  .marketplace-search-input {
    .v-input__slot {
      fieldset {
        border: none !important;
      }
    }
  }

  // Table
  .v-data-table--mobile tr:nth-child(odd) {
    border-left: 4px solid deeppink;
  }

  .v-data-table--mobile tr:nth-child(even) {
    border-left: 4px solid cyan;
  }
}

@include theme(marketplace-search-input) using ($material) {
  .v-input__slot {
    input {
      caret-color: map-deep-get($material, 'text', 'primary') !important;
    }
  }
}
</style>
