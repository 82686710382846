import axios from '@axios'

// import BaseApiService from '../apiBase'

// interface ContactRequestInterface {
//     id: string;
//     name: string;
// }

// ContactRequest Router list
// GET    /marketplace/contact_requests             Retrieves the collection of ContactRequest resources.
// POST   /marketplace/contact_requests             Creates a ContactRequest resource.
// GET    /marketplace/contact_requests/{id}        Retrieves a ContactRequest resource.
// PUT    /marketplace/contact_requests/{id}        Replaces a ContactRequest resource.
// DELETE /marketplace/contact_requests/{id}        Removes a ContactRequest resource.
//
// Other Actions
// POST   /marketplace/contact_requests/status/{id}  Change status  of a ContactRequest resource.
// POST   /marketplace/contact_requests/starred/{id} Change starred of a ContactRequest resource.
// POST   /marketplace/contact_requests/labels/{id}  Change label   of a ContactRequest resource.
const routeMarketplaceContactRequests = '/marketplace/contact_requests'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class ContactRequest extends BaseApiService { constructor() { super(axios)}
class ContactRequest {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchContactRequests(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketplaceContactRequests}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Create a new ContactRequest
  // Request:  status, productSupplierId, subject, message
  // Response: contactRequest: ContactRequest
  createContactRequest(contactRequestData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketplaceContactRequests}`, { ...contactRequestData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchContactRequest(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketplaceContactRequests}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Not in use - Similar to Create
  updateContactRequest(contactRequestData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeMarketplaceContactRequests}/${contactRequestData.id}`, { ...contactRequestData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeContactRequest(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeMarketplaceContactRequests}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // *** Other actions //

  // { contactRequestId, status: [publish | withdraw] }
  setContactRequestStatus(contactRequestId, newStatus) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketplaceContactRequests}/status/${contactRequestId}`, { status: newStatus })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { contactRequestId, isStarred: [true | false] }
  changeContactRequestStarred(contactRequestId, newIsStarred) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketplaceContactRequests}/starred/${contactRequestId}`, { isStarred: newIsStarred })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { contactRequestId, label: ["important" | "company" ...] }
  changeContactRequestLabels(contactRequestId, toggleLabel) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketplaceContactRequests}/labels/${contactRequestId}`, { label: toggleLabel })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useContactRequest (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useContactRequest = new ContactRequest()
export default useContactRequest
