<template>
  <v-card>
    <!-- Top Action: Navigate to Product -->
    <div class="pa-5">
      <v-list-item
        link
        :to="menuItemTopAction.route"
      >
        <v-btn
          outlined
          block
          color="primary"
        >
          <v-icon size="22">
            {{ menuItemTopAction.icon }}
          </v-icon>
          <span class="ml-2">{{ menuItemTopAction.title }}</span>
        </v-btn>
      </v-list-item>
    </div>

    <v-card-title>
      <!-- Search &amp; Filter -->
      {{ $t('ProductFilters.title') }}
    </v-card-title>

    <!-- Opciones de lista -->
    <v-divider></v-divider>
    <div class="d-flex align-center px-4 py-2">
      <v-checkbox
        class="d-flex mt-0 pt-0"
        hide-details
        :input-value="selectAllProductDemanderCheckbox"
        :indeterminate="isSelectAllProductDemanderCheckboxIndeterminate"
        :disabled="!productDemanderFilterOptions.length"
        @change="selectAllCheckboxUpdate"
      ></v-checkbox>
    </div>

    <!-- Lista -->
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-contact-requests-list"
    >
      <v-hover
        v-for="productDemander in productDemanderFilterOptionsLocal"
        #default="{ hover: isHovered }"
        :key="productDemander.id"
      >
        <div
          :class="[{'hovered elevation-3': isHovered}, { 'product-demander-item-selected': productDemander.isSelected }]"
          class="product-demander-item d-flex align-center text-no-wrap cursor-pointer pa-4"
          @click="toggleSelectedProductDemander(productDemander.id)"
        >
          <v-checkbox
            class="d-flex mt-0 pt-0"
            hide-details
            :input-value="selectedProductDemanders.includes(productDemander.id)"
            @click.stop
            @change="toggleSelectedProductDemander(productDemander.id)"
          ></v-checkbox>

          <ProductDemanderCard :product-demander="productDemander" />
        </div>
      </v-hover>
    </perfect-scrollbar>
  </v-card>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import useRouterPath from '@/router/useRouterPath'

// Components
import ProductDemanderCard from '@/components/Cards/ProductDemanderCard.vue'

export default {
  components: {
    ProductDemanderCard,

    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    // Filter: Category
    productDemanderFilterIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    productDemanderFilterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { menuItemProductDemander: menuItemTopAction } = useRouterPath()

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // ProductDemanders Options
    const productDemanderFilterOptionsLocal = ref(JSON.parse(JSON.stringify(props.productDemanderFilterOptions)))
    watch(
      () => props.productDemanderFilterOptions,
      () => {
        productDemanderFilterOptionsLocal.value = JSON.parse(JSON.stringify(props.productDemanderFilterOptions))

        // debugger
        // Esta es la propuesta inicial (Seleccionamos todas las opciones)
        // selectedProductDemanders.value = productDemanderFilterOptionsLocal.value.map(item => item.id)
        // eslint-disable-next-line no-use-before-define
        // selectAllCheckboxUpdate(true)
      },
    )

    // ————————————————————————————————————
    //* ——— ProductDemanders Selection
    // ————————————————————————————————————
    const selectedProductDemanders = ref(JSON.parse(JSON.stringify(props.productDemanderFilterIds)))

    // Actualizamos cuando se produzcan diferencias
    const firstWatchInit = ref(false)
    watch(
      () => props.productDemanderFilterIds,
      () => {
        firstWatchInit.value = true
        selectedProductDemanders.value = JSON.parse(JSON.stringify(props.productDemanderFilterIds))
      },
    ) // , { deep: true })

    // Observamos los cambios del array
    // No es necesario { deep: true } porque los cambios en el array son siempre de tamaño
    watch(
      () => selectedProductDemanders.value,
      () => {
        // FirstWatchInit: Los cambios son de inicialización (Proceden del Padre): No sincronizamos
        if (!firstWatchInit.value) {
          emit('update:productDemanderFilterIds', selectedProductDemanders.value)
        }
        firstWatchInit.value = false
      },
    )

    const toggleSelectedProductDemander = productDemanderId => {
      const productDemanderIndex = selectedProductDemanders.value.indexOf(productDemanderId)

      if (productDemanderIndex === -1) selectedProductDemanders.value.push(productDemanderId)
      else selectedProductDemanders.value.splice(productDemanderIndex, 1)
    }
    const selectAllProductDemanderCheckbox = computed(
      () => productDemanderFilterOptionsLocal.value.length
        && productDemanderFilterOptionsLocal.value.length === selectedProductDemanders.value.length,
    )
    const isSelectAllProductDemanderCheckboxIndeterminate = computed(
      () => Boolean(selectedProductDemanders.value.length)
        && productDemanderFilterOptionsLocal.value.length !== selectedProductDemanders.value.length,
    )
    const selectAllCheckboxUpdate = val => {
      selectedProductDemanders.value = val
        ? productDemanderFilterOptionsLocal.value.map(productDemander => productDemander.id)
        : []
    }

    // Clear all filters
    const clearFilters = () => {
      selectAllCheckboxUpdate(false)
    }

    // ? Watcher to reset selectedProductDemanders is somewhere below due to watch dependecy fullfilment

    // const customActionFinished = {
    //   title: 'Withdraw',
    //   value: CONTACT_REQUEST_STATUS.FINISHED,
    //   color: 'error',
    //   icon: mdiClose,
    // }

    return {
      perfectScrollbarSettings,

      // Events
      clearFilters,
      menuItemTopAction, // Link

      // Data
      productDemanderFilterOptionsLocal, // Options
      // productDemanderFilterLocal, // TODO Output

      // ProductDemander Selection
      selectedProductDemanders,
      toggleSelectedProductDemander,
      selectAllProductDemanderCheckbox,
      isSelectAllProductDemanderCheckboxIndeterminate,
      selectAllCheckboxUpdate,
    }
  },
}
</script>
