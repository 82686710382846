<template>
  <!-- TODO - Por ahora primary, cuando tengamos un indicador de bloqueo lo cambiamos -->
  <v-tooltip
    top
    :color="contactRequest.id ? 'primary' : 'grey lighten-1'"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Desactivamos cuando No sea navegable y el indicador === false -->
      <!-- :to="menuItemContactRequest.route" -->
      <v-icon
        class="mx-2"
        :color="contactRequest.id ? 'primary' : 'grey lighten-1'"
        :disabled="(!isNavegable || !contactRequest.id)"
        v-bind="attrs"
        v-on="on"
        @click.stop="goTo(menuItemContactRequest.route, contactRequest)"
      >
        {{ menuItemContactRequest.icon }}
      </v-icon>
    </template>
    <span>{{ menuItemContactRequest.title }}</span>
  </v-tooltip>
</template>

<script>
import { mdiEye } from '@mdi/js'

// Router
// import useRouterPath from '@/router/useRouterPath'
import { useRouter } from '@core/utils'
import { resolveContactRequestRoute } from '@/services/master/ContactRequestStatus'

export default {
  props: {
    contactRequest: {
      type: Object,
      required: true,
    },
    isNavegable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Control de navegación
    const { router } = useRouter()

    // const { menuItemContactRequest } = useRouterPath()
    const menuItemContactRequest = Object.assign(
      resolveContactRequestRoute(props.contactRequest.status),
      {
        icon: mdiEye,
        title: 'Go to ContactRequest',
      },
    )

    // Go to ChatMessage by contactRequest: Añadimos el query a la ruta
    const goTo = (toRoute, contactRequest) => {
      // Exit if not navegable
      if (!props.isNavegable) {
        return
      }
      const newRoute = { ...toRoute, query: { contactRequestId: contactRequest.id } }
      router.replace(newRoute)
    }

    return {
      // Router navigation
      menuItemContactRequest,
      goTo,
    }
  },
}
</script>
