<template>
  <!-- TODO - Por ahora primary, cuando tengamos un indicador de bloqueo lo cambiamos -->
  <v-tooltip
    top
    :color="contact.id ? 'primary' : 'grey lighten-1'"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Desactivamos cuando No sea navegable y el indicador === false -->
      <!-- :to="menuItemChat.route" -->
      <v-icon
        class="mx-2"
        :color="contact.id ? 'primary' : 'grey lighten-1'"
        :disabled="(isNavegable && !contact.id)"
        v-bind="attrs"
        v-on="on"
        @click.stop="goTo(menuItemChat.route, contact)"
      >
        {{ menuItemChat.icon }}
      </v-icon>
    </template>
    <!-- Marketplace Available  -->
    <!-- {{ menuItemChat.title }} -->
    <span>{{ menuItemChat.title }}? {{ contact.id ? '' : '(off)' }}</span>
  </v-tooltip>
</template>

<script>

// Router
import { useRouter } from '@core/utils'
import useRouterPath from '@/router/useRouterPath'

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    isNavegable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Control de navegación
    const { menuItemChat } = useRouterPath()
    const { router } = useRouter()

    // Go to ChatMessage by contact: Añadimos el query a la ruta
    const goTo = (toRoute, contact) => {
      // Exit if not navegable
      if (!props.isNavegable) {
        return
      }
      const newRoute = { ...toRoute, query: { contactId: contact.id } }

      router.replace(newRoute)
    }

    return {
      // Router navigation
      menuItemChat,
      goTo,
    }
  },
}
</script>
