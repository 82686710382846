import { mdiAlertCircle } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useMarketplaceHandler from '../useMarketplaceHandler'

// Services
import useMarketplace from '@/services/api/modules/marketplace'

export default function useMarketplaceCatalog(initProduductDemanderId) {
  const { sendMarketplaceNotification } = useMarketplaceHandler()

  const itemListTable = ref([])
  const refTable = ref(null)

  // Table Handlers
  // { key: 'name', sortable: true },
  // { key: 'product', label: 'CATEGORY / PRODUCT', sortable: true },
  // { key: 'productConservation', sortable: true },
  // { key: 'productPresentation', sortable: true },
  // { key: 'tags', sortable: false },
  // { key: 'actions' },

  const tableColumns = [
    { text: 'NAME', value: 'name', sortable: true },
    { text: 'CATEGORY PRODUCT', value: 'productCategory', sortable: true },
    { text: 'CONSERVATION', value: 'productConservation', sortable: true },
    { text: 'PRESENTATION', value: 'productPresentation', sortable: true },
    { text: 'TAGS', value: 'tags', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const loading = ref(false)

  // Añadimos este atributo en options pero podría ir solo - Hay que indicarlo en el footer-props
  // ItemsPerPageOptions: [10, 25, 50, 100] - https://vuetifyjs.com/en/api/v-data-footer/#props-items-per-page-options
  // :footer-props="{
  //   'items-per-page-options': options.itemsPerPageOptions // [10, 25, 50, 100]
  // }"

  // DataOptions object https://vuetifyjs.com/en/api/v-data-table/#props-options
  //  page: number,
  //  itemsPerPage: number,
  //  sortBy: string[],
  //  sortDesc: boolean[],
  //  groupBy: string[],
  //  groupDesc: boolean[],
  //  multiSort: boolean,
  //  mustSort: boolean
  // El resto de los atributos los añade v-data-table al sincronizar
  const options = ref({
    // Pagination
    page: 1,
    itemsPerPage: 5,

    // Sort default: name ASC
    sortBy: ['name'],
    sortDesc: [false],
    multiSort: false,
    mustSort: false,

    // Group (Lo indicamos para que v-data-table no genere cambios sobre options y evitar que watch haga reload)
    groupBy: [],
    groupDesc: [],
  })

  const itemsPerPageOptions = ref([1, 2, 5, 10, 15, -1])
  const serverItemsLength = ref(0) // Total de resultados sin paginar
  const selectedRows = ref([]) // No se usa por el momento

  // Filters
  const searchQueryFilter = ref('')
  const countryFilter = ref(null) // CountryKey: [ES, EN]

  // Filters (Custom)
  const countFiltering = ref(0)
  const productDemanderIdsFilter = ref([])
  watch(() => productDemanderIdsFilter.value, () => {
    countFiltering.value = productDemanderIdsFilter.value.length
  })

  const productDemanderFilterOptions = ref([])
  const fetchMarketplaceProductDemanders = () => {
    useMarketplace.fetchProductDemanders({
      isMarketplaceAvailable: false,
    })
      .then(response => {
        const { productDemanders } = response.data
        if (productDemanders) {
          productDemanderFilterOptions.value = response.data.productDemanders
        } else {
          productDemanderFilterOptions.value = []
        }

        // Check Router init value has this filter
        productDemanderIdsFilter.value = productDemanderFilterOptions.value.findIndex(item => item.id === initProduductDemanderId) > -1 ? [initProduductDemanderId] : []
      })
  }
  fetchMarketplaceProductDemanders()

  // const fetchProductSuppliers = () => useProductSupplier.fetchProductSuppliers({})
  const fetchMarketplaceProductSuppliers = () => {
    loading.value = true
    selectedRows.value = []

    useMarketplace.fetchProductSuppliers({
      q: searchQueryFilter.value,
      perPage: options.value.itemsPerPage,
      page: options.value.page,
      sortBy: options.value?.sortBy[0],
      sortDesc: options.value?.sortDesc[0],

      // Custom filters
      productDemanderIds: productDemanderIdsFilter.value,
      countryCode: countryFilter.value, // ES, EN
    })
      .then(response => {
        const { productSuppliers, total } = response.data

        itemListTable.value = productSuppliers
        serverItemsLength.value = total
      })
      .catch(() => {
        sendMarketplaceNotification('Error fetching catalog', 'error', mdiAlertCircle)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const refetchDataByFilters = () => {
    // Cuando aplicamos filtros el resultado ya no va acorde a la página actual
    if (options.value.page !== 1) {
      options.value.page = 1 // Esto ya va a provocar un Fetch
    } else {
      fetchMarketplaceProductSuppliers()
    }
  }

  // Paginations
  //  Page:                           Solo cambio de página
  //  ItemsPerPage, sortBy, sortDesc: Forzamos a Page 1
  watch(
    [
      () => options.value.page,
      () => options.value.itemsPerPage,
      () => options.value.sortBy[0],
      () => options.value.sortDesc[0],
    ],
    (newValue, oldValue) => {
      // Changes on: ItemsPerPage OR sortBy OR sortDesc
      if (newValue[1] !== oldValue[1] // ItemsPerPage
        || newValue[2] !== oldValue[2] // sortBy has changed
        || newValue[3] !== oldValue[3] // sortDesc has changed
      ) {
        refetchDataByFilters()
      } else {
        fetchMarketplaceProductSuppliers()
      }
    },
  )

  // Filter (Array of Ids)
  //  Observamos los cambios del array
  //  No es necesario { deep: true } porque los cambios en el array son siempre de tamaño
  watch(
    () => productDemanderIdsFilter.value,
    () => {
      refetchDataByFilters()
    },
  )

  // array of multiple sources
  // watch([searchQueryFilter, countryFilter]): No necesitamos { deep: true }
  watch(
    [
      searchQueryFilter,
      countryFilter,
    ],
    () => {
      refetchDataByFilters()
    },
  )

  // Watch con multiples sources
  //  productDemanderIdsFilter: integer[] -> usamos { deep: true }
  //  searchQueryFilter, countryFilter
  // watch(
  //   [
  //     productDemanderIdsFilter,
  //     countryFilter,
  //     searchQueryFilter,
  //   ],
  //   () => {
  //     debugger
  //     refetchDataByFilters()
  //   }, { deep: true },
  // )

  return {
    // Table
    refTable,
    tableColumns,
    options,
    itemsPerPageOptions, // Select Item per Page

    loading,
    selectedRows,
    itemListTable, // data
    serverItemsLength,

    // Fetch data
    // fetchProductDemanders, // Esto se ha implementado aquí
    fetchMarketplaceProductSuppliers,

    // Filters
    searchQueryFilter,
    countryFilter,

    // Filters: Country, ProductDemanderIds
    countFiltering,
    productDemanderIdsFilter,
    productDemanderFilterOptions,
  }
}
