import axios from '@axios'

// Especie
// interface ProductInterface {
//     id: string;
//     value: string;
//     name: string;
//     productCategory: any
//   }

// Marketplace Router list
// GET    /product/suppliers             Retrieves the collection of ProductSupplier resources.
// POST   /product/suppliers             Creates a ProductSupplier resource.
// GET    /product/suppliers/{id}        Retrieves a ProductSupplier resource.
// POST   /product/suppliers/media/{id}  Upload a ProductSupplier image. (multipart/form-data only in POST or GET)
// PUT    /product/suppliers/{id}        Replaces a ProductSupplier resource.
// DELETE /product/suppliers/{id}        Removes a ProductSupplier resource.
// const routeMarketplace = '/marketplace'

const routeMarketplaceProductSuppliers = '/marketplace/product/suppliers'
const routeMarketplaceProductDemanders = '/marketplace/product/demanders'
const routeMarketplaceProductSupplierFavorites = '/marketplace/product/supplier/favorites'
const routeMarketplaceSuppliers = '/marketplace/suppliers'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class Marketplace extends BaseApiService { constructor() { super(axios) }
class Marketplace {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  //         .post(`${this.API_BASE}${routeMarketplace}`, { productSupplierId })
  //         .delete(`${this.API_BASE}${routeMarketplace}/${id}`)

  // *** Product Suppliers (Catalog) ***
  fetchProductSuppliers(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketplaceProductSuppliers}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Individual Product Supplier
  fetchProductSupplier(productSupplierId) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketplaceProductSuppliers}/${productSupplierId}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  //
  // *** Products Demanders (Interest list) ***

  fetchProductDemanders(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketplaceProductDemanders}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  //
  // *** Suppliers
  fetchSuppliers(payload) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .get('/marketplace/suppliers', { params: payload })
        .get(`${this.API_BASE}${routeMarketplaceSuppliers}`, { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchSupplier(supplierId) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .get(`/marketplace/suppliers/${supplierId}`)
        .get(`${this.API_BASE}${routeMarketplaceSuppliers}/${supplierId}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchWishlistProducts() {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get('/marketplace/wishlist')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // ------------------------------------------------
  // Product Supplier Favorites: Actions
  // ------------------------------------------------
  fetchProductSupplierFavorites(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketplaceProductSupplierFavorites}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createProductSupplierFavorite(productSupplierId) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .post('/marketplace/wishlist', { productSupplierId })
        .post(`${this.API_BASE}${routeMarketplaceProductSupplierFavorites}/add`, { productSupplierId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeProductSupplierFavorite(productSupplierId) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .delete(`${this.API_BASE}${routeMarketplaceProductSupplierFavorites}/${productSupplierId}`)
        .post(`${this.API_BASE}${routeMarketplaceProductSupplierFavorites}/delete`, { productSupplierId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  sendProductRequest({ ...args }) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketplaceProductSupplierFavorites}/send-request`, args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProduct
const useMarketplace = new Marketplace()
export default useMarketplace
