<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    width="500"
    @click:outside="$emit('update:is-dialog-active',false)"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="$emit('update:is-dialog-active',false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
          <span class="ml-1">{{ buttonTextCancel }}</span>
        </v-btn>

        <!-- @click="$emit('update:is-dialog-active',false); onProductSupplierConnect()" -->
        <!-- @click="$emit('update:is-dialog-active',false); $emit('connect',id)" -->
        <v-btn
          color="primary"
          outlined
          text
          @click="$emit('update:is-dialog-active',false); $emit('connect',id)"
        >
          <v-icon>
            {{ icons.mdiEmailSendOutline }}
          </v-icon>
          <span class="ml-2">{{ buttonText }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiEmailSendOutline } from '@mdi/js'

export default {
  model: {
    prop: 'isDialogActive',
    event: 'update:is-dialog-active',
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Connect',
    },
    text: {
      type: String,
      default: 'Will send a contact Request',
    },
    buttonText: {
      type: String,
      default: 'Contact',
    },
    buttonTextCancel: {
      type: String,
      default: 'Cancel',
    },
  },
  setup(props, { emit }) {
    const onProductSupplierConnect = () => {
      emit('connect', props.id)
    }

    return {
      onProductSupplierConnect,
      icons: {
        mdiClose,
        mdiEmailSendOutline,
      },
    }
  },
}
</script>
