<template>
  <UserCard
    :user="supplier.user"
    :company="supplier.company"
  ></UserCard>
</template>
<script>

import UserCard from '@/components/Cards/UserCard.vue'

export default {
  components: {
    UserCard,
  },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
  },
}
</script>
