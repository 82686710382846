<template>
  <v-card>
    <!-- <v-card-title class="d-flex">
      <span>{{ productSupplier.name }}</span>
    </v-card-title>
    <v-divider></v-divider> -->

    <!-- @click.stop="$emit('on-supplier-click', productSupplier.supplier)" -->
    <!-- SupplierCard -->
    <v-card-text class="d-flex align-center">
      <SupplierCard :supplier="productSupplier.supplier" />

      <!-- Right Side: Chat or ContactRequet or CreateContactRequest-->
      <v-spacer></v-spacer>
      <ContactChatMessageNavegable
        v-if="productSupplier.contactRelation"
        :contact="productSupplier.contactRelation"
        :is-navegable="true"
      />
      <ContactRequestNavegableIcon
        v-if="productSupplier.contactRequestRelation"
        :contact-request="productSupplier.contactRequestRelation"
        :is-navegable="true"
      />
      <!-- @click.stop="$emit('on-action-connect', productSupplier)" -->
      <v-btn
        v-if="!productSupplier.contactRequestRelation && !productSupplier.contactRelation"
        color="primary"
        outlined
        icon
        @click.stop="$emit('on-action-view', productSupplier)"
      >
        <v-icon>
          {{ icons.mdiEmailSendOutline }}
        </v-icon>
      </v-btn>

      <!-- Marketplace navegable ? -->
      <!-- <ProductDemanderCampaignInboundNavegableIcon :product-demander="productSupplier"></ProductDemanderCampaignInboundNavegableIcon> -->
    </v-card-text>
    <v-divider></v-divider>

    <!-- ProductSupplierCard -->
    <v-card-text>
      <ProductSupplierCard :product-supplier="productSupplier" />
    </v-card-text>

    <!-- Conservation / Presentation -->
    <v-card-text class="d-flex flex-row align-center">
      <span class="text-nowrap font-weight-bold">
        {{ productSupplier.productConservation.name }}
      </span>
      <v-spacer></v-spacer>
      <span class="text-nowrap font-weight-bold">
        {{ productSupplier.productPresentation.name }}
      </span>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <!-- Customer Tags -->
    <v-card-text class="d-flex flex-row align-center">
      <div class="badge-wrapper">
        <v-chip
          v-for="tag in productSupplier.tags"
          :key="tag"
          small
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ tag }}
        </v-chip>
      </div>
    </v-card-text>

    <!-- Description -->
    <v-card-text>
      <div class="d-flex flex-column">
        <!-- <span>{{ productSupplier.name }}</span> -->
        <small
          class="text-truncate text-muted"
          style="height: 24px;"
        >
          {{ filterTags(productSupplier.description) }}
        </small>
      </div>
    </v-card-text>

    <!-- Actions -->
    <!-- <v-divider></v-divider> -->
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        text
        @click.stop="$emit('on-action-view', productSupplier)"
      >
        <v-icon>
          {{ icons.mdiEye }}
        </v-icon>
        Ver
      </v-btn>

      <v-btn
        color="primary"
        outlined
        text
        @click.stop="$emit('on-action-connect', productSupplier)"
      >
        <v-icon>
          {{ icons.mdiEmailSendOutline }}
        </v-icon>
        Contactar
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
<script>
import { mdiEmailSendOutline, mdiEye } from '@mdi/js'
import { ref } from '@vue/composition-api'

// UX
import { filterTags } from '@core/utils/filter'

// Components
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import SupplierCard from '@/components/Cards/SupplierCard.vue'

import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'
import ContactRequestNavegableIcon from '@/components/NavegableIcon/ContactRequestNavegableIcon.vue'

// Services
// import useProductSupplierHandler from '../useProductSupplierHandler'

export default {
  components: {
    SupplierCard,
    ProductSupplierCard,
    // eslint-disable-next-line vue/no-unused-components
    ContactChatMessageNavegable,
    ContactRequestNavegableIcon,
  },
  props: {
    productSupplier: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // Role colors, Seller and purchaser colors
    // const { resolveProductSupplierStatus } = useProductSupplierHandler()

    const contact = ref(null)

    return {
      contact,

      // UI
      filterTags,

      // resolveProductSupplierStatus,

      // icons
      icons: {
        mdiEye,
        mdiEmailSendOutline,
      },
    }
  },
}
</script>
